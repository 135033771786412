<template>
    <div id="bg">
        <div class="search">
            <div class="search-box">
                <span class="search-box-title">订单号：</span>
                <el-input v-model="search.order" class="width_200" placeholder="请输入订单号" size="medium" clearable></el-input>
            </div>
            <div class="search-box">
                <span class="search-box-title">银商账号：</span>
                <el-input v-model="search.ys_account" class="width_200" placeholder="请输入银商账号" size="medium" clearable></el-input>
            </div>
          <div class="search-box">
            <span class="search-box-title">用户ID：</span>
            <el-input v-model="search.uid" class="width_200" placeholder="请输入用户ID" size="medium" clearable></el-input>
          </div>
            <div class="search-box">
                <span class="search-box-title">用户账号：</span>
                <el-input v-model="search.username" class="width_200" placeholder="请输入银商账号" size="medium" clearable></el-input>
            </div>
            <div class="search-box">
                <span class="search-box-title">时间：</span>
                <el-date-picker v-model="search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                value-format="YYYY-MM-DD"></el-date-picker>
            </div>
            <div class="search-box">
                <span class="search-box-title">状态：</span>
                <el-select v-model="search.status" class="width_100" filterable placeholder="全部" size="medium" clearable>
                    <el-option key="-1" label="全部" value="-1"></el-option>
                  <el-option key="0" label="待支付" value="0"></el-option>
                  <el-option key="1" label="已支付" value="1"></el-option>
                  <el-option key="2" label="已取消" value="2"></el-option>
                </el-select>
            </div>
            <div class="search-box">
                <el-button type="primary" size="medium" @click="(pager.page = 1),getList()">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
            <el-table-column prop="order" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="ys_uid" label="银商ID" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="ys_username" label="银商账号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="uid" label="用户ID" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="username" label="用户账号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="pay_type" label="充值项目" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <span v-if="scope.row.pay_type == 0">钱包充值</span>
                <span v-if="scope.row.pay_type > 100">VIP充值</span>
              </template>
            </el-table-column>
            <el-table-column prop="type_title" label="支付类型" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="addtime" label="时间" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <span>{{timeToDate(scope.row.addtime)}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="金额" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
                <template #default="scope">
                    <div v-if="scope.row.status == 0 ">待支付</div>
                    <div v-if="scope.row.status == 1 " :style="{color: '#33C933'}">已支付</div>
                    <div v-if="scope.row.status == 2 " :style="{color: '#F33333'}">已取消</div>
                </template>
            </el-table-column>
          <el-table-column prop="remark" label="备注" align="center" :show-overflow-tooltip="true"></el-table-column>

          <el-table-column label="操作" width="200px" align="center">
                <template #default="scope">
                    <span class="operation" @click="editScore(scope.row)">追分</span>
                  <span class="operation" @click="messageLog(scope.row)">聊天记录</span>
                </template>
            </el-table-column>
        </el-table>
        <page :pager="pager" @query="getList()" />

        <!-- 追分 -->
      <el-dialog custom-class="dialog" title="追分" v-model="dialogScore" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
        <div class="form_box">
          <div class="form_title">余额：</div>
          <el-input v-model="dialogArr.price" placeholder="余额" size="small"></el-input>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="updateUserScore">确认修改</el-button>
          </span>
        </template>
      </el-dialog>


      <!-- 聊天记录弹窗 -->
      <el-dialog custom-class="dialog" :title="title" v-model="dialog" width="700px" top="10vh" :close-on-click-modal="false" @close="closeDialog">
        <div v-for="(item, i) in messageLogData" :key="i">
          <div v-if="item.from == 1">
            <!-- 发送给用户 -->
            <span style="color: blue">[{{ row.ys_uid }}]{{ row.ys_username }}</span><span style="margin-left: 30px;">{{ timeToDate(item.addtime) }}</span>
            <div v-for="(val, j) in item" :key="j">
              <div v-if="val.msg_type == 'TIMTextElem'">
                <div v-html="val.msg_content.text"></div>
              </div>
              <div v-if="val.msg_type == 'TIMFinishElem'">
                <div v-html="val.msg_content.title"></div>
              </div>
              <div v-if="val.msg_type == 'TIMPayTypeElem'">
                <p>{{ val.msg_content.title }}-{{ row.price }}元</p>
                <p>请选择您的付款方式</p>
                <div>
                  <div v-for="(val2, k) in val.msg_content.pay_info" :key="k" style="display:inline-block;margin-left: 20px;">
                    <img :src="require('@/assets/recharge/' + val2.icon)" />
                    <p>{{ val2.title }}</p>
                  </div>
                </div>
              </div>
              <div v-if="val.msg_type == 'TIMImageElem'">
                <ximg
                    style="width: 150px; height: 150px"
                    :src="val.msg_content.images[0].url">
                </ximg>
              </div>
              <div v-if="val.msg_type == 'TIMPayDetailElem'">
                <div>
                  <p>{{ val.msg_content.title }}</p>
                  <div v-if="val.msg_content.pay_type != 3">
                    <ximg
                        style="width: 150px; height: 150px"
                        :src="val.msg_content.qrcode_url">
                    </ximg>
                  </div>
                  <div v-else>
                    <div>
                      <span>卡号：{{ val.msg_content.account }}</span>
                    </div>
                    <div>
                      <span>银行名称：{{ val.msg_content.type_name }}</span>
                    </div>
                    <div>
                      <span>真实姓名：{{ val.msg_content.real_name }}</span>
                    </div>
                    <div>
                      <span>开户地址：{{ val.msg_content.addr }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <span style="color: green">[{{ row.uid }}]{{ row.username }}</span><span style="margin-left: 30px;">{{ timeToDate(item.addtime) }}</span>
            <div v-for="(val, j) in item" :key="j">
              <div v-if="val.msg_type == 'TIMTextElem'">
                <div v-html="val.msg_content.text"></div>
              </div>
              <div v-if="val.msg_type == 'TIMFinishElem'">
                <div v-html="val.msg_content.title"></div>
              </div>
              <div v-if="val.msg_type == 'TIMPayTypeElem'">
                <p>{{ val.msg_content.title }}</p>
                <p>请选择您的付款方式</p>
                <div>
                  <div v-for="(val2, k) in val.msg_content.pay_info" :key="k" style="display:inline-block;margin-left: 20px;">
                    <img :src="require('@/assets/recharge/' + val2.icon)" />
                    <p>{{ val2.title }}</p>
                  </div>
                </div>
              </div>
              <div v-if="val.msg_type == 'TIMImageElem'">
                <ximg
                    style="width: 150px; height: 150px"
                    :src="val.msg_content.images[0].url">
                </ximg>
              </div>
              <div v-if="val.msg_type == 'TIMPayDetailElem'">
                <div>
                  <p>{{ val.msg_content.title }}</p>
                  <div v-if="val.msg_content.pay_type != 3">
                    <ximg
                        style="width: 150px; height: 150px"
                        :src="val.msg_content.qrcode_url">
                    </ximg>
                  </div>
                  <div v-else>
                    <div>
                      <span>卡号：{{ val.msg_content.account }}</span>
                    </div>
                    <div>
                      <span>银行名称：{{ val.msg_content.type_name }}</span>
                    </div>
                    <div>
                      <span>真实姓名：{{ val.msg_content.real_name }}</span>
                    </div>
                    <div>
                      <span>开户地址：{{ val.msg_content.addr }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template #footer>
          <span class="dialog-footer"></span>
        </template>
      </el-dialog>

    </div>
  </template>
  
  <script>
  import { getList, updateUserScore } from "@/api/ys/payLog";
  import page from "@/components/page";
  import {getMessageLog} from "@/api/ys/messageLog";
  import Ximg from "@/components/ximg.vue";
  export default {
    name: "payLog",
    components: {
      Ximg,
        page,
    },
    data() {
      return {
        search: {
            //搜索
            order: "",
            username: "",
            ys_account: "",
            searchDate: [], //时间
            status: "-1",
            uid:0,
        },
        pager: { total: 0, page: 1, rows: 10 },
        tableData: [], //数据
        dialogArr: {
          price: "", // 余额
        },
        dialogScore: false, // 追分
        dialog: false, // 聊天记录框
        title: "", // 聊天记录标题
        messageLogData: [], // 聊天记录数据
      };
    },
    computed: {},
    created() {},
    mounted() {
      this.getList();
    },
    methods: {
      messageLog(row) {
        this.row = row;
        getMessageLog([
          { key: "orderNum", val: row.order },
        ]).then((res) => {
          if (res.code == 0) {
            res.data.forEach(item => {
              item.msg_log = JSON.parse(item.msg_log);
              if(item.msg_log.msg_type == "TIMTextElem"){
                item.msg_log.msg_content.text = decodeURIComponent(item.msg_log.msg_content.text)
              }
            })
            console.log(res.data)
            this.messageLogData = res.data;
            this.title = "[" + row.uid + "]" + row.username + " - " + "[" + row.ys_uid+ "]" + row.ys_username + " 的聊天记录";
            this.dialog = true;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
            .catch((err) => {
              console.log(err);
            });
      },
      timeToDate(time){
        if (time == 0){
          return "-"
        }
        return this.$common.timeToDate(time)
      },
      //获取消息列表
      getList() {
        getList([
            { key: "page", val: this.pager.page - 1 },
            { key: "row", val: this.pager.rows },
            { key: "orderNum", val: this.search.order },
            { key: "user_id", val: this.search.uid },
            { key: "account", val: this.search.username },
            { key: "ysAccount", val: this.search.ys_account },
            { key: "begin_time", val: this.search.searchDate && this.search.searchDate.length > 0 ? this.search.searchDate[0] : "" },
            { key: "end_time",val: this.search.searchDate && this.search.searchDate.length > 0 ? this.search.searchDate[1] : "" },
            { key: "status", val: this.search.status },
        ])
          .then((res) => {
            if (res.code == 0) {
              if (this.pager.page == 1){
                this.pager.total = res.data.total;
              }
                this.tableData = res.data.list;
            } else {
                this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 追分
      editScore(row){
        this.row = row;
        this.dialogScore = true;
      },
      updateUserScore(){
        if(this.dialogArr.price < 0 ) {
          return this.$message({ message: "追分金额不能小于0", type: "error" });
        }
        this.$prompt("请输入谷歌验证码,未绑定则跳过", '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
          inputErrorMessage: '谷歌验证码只能是数字'
        }).then(({ value }) => {
          updateUserScore([
            { key: "id", val: this.row.id },
            { key: "price", val: this.dialogArr.price },
            { key: "google_code", val: value },
          ]).then((res) => {
            if (res.code == 0) {
              this.$message({ message: res.msg, type: "success" });
              this.dialogScore = false;
              this.getList();
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
              .catch((err) => {
                console.log(err);
              });
        }).catch(() => {
        });

      },

    },
  };
  </script>
  
  <style scoped>
    .form_box {
        overflow: hidden;
        margin-bottom: 18px;
    }
    .form_title {
        width: 30%;
        float: left;
        height: 32px;
        line-height: 32px;
    }
    .form_box >>> .el-input,
    .form_box >>> .el-textarea {
        width: 60%;
    }
    .form_box >>> .el-select .el-input {
        width: 100%;
    }
  </style>