import axios from 'axios';
import Qs from 'qs';
import { ElMessage } from 'element-plus'
import { orbital } from '@/common/rule'

//测试环境
axios.defaults.baseURL = "";

//正式环境
// axios.defaults.baseURL = "http://yousu.zjqichuang.com/"

// 设置请求超时时间
axios.defaults.timeout = 10000;

// 设置post请求头

// 请求拦截
axios.interceptors.request.use(config => {
    // 在发送请求之前做些什么 验证token之类的
    return config;
}, error => {
    // 对请求错误做些什么
    ElMessage.error({ message: '请求超时!' })
    return Promise.error(error);
})

// 响应拦截
axios.interceptors.response.use(response => {
    // 对响应数据做点什么
    // console.log(response);
    return response;

}, error => {
    // 对响应错误做点什么

    return Promise.reject(error);
});


// 封装get方法和post方法

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    if (params == undefined) {
        params = {
            token: localStorage.getItem("token")
        }
    } else {
        params['token'] = localStorage.getItem("token")
    }
    return new Promise((resolve, reject) => {
        axios.get(url, params).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
            ElMessage({ message: '加载失败', type: 'error' });
        })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
// param => {key: {val:val, reg: reg, title: title}}
export function post(url, params) {
    axios.defaults.headers['Content-Type'] = 'multipart/form-data;charset-utf-8';
    axios.defaults.headers['Name'] = params.name;
    axios.defaults.headers['Token'] = localStorage.getItem("token");
    axios.defaults.headers['Uid'] = localStorage.getItem("uid");
    return new Promise((resolve, reject) => {
        let data = new FormData();
        data.append('uploadfile',params.file)
        axios.post(url, data)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
                console.log(err);
                ElMessage({ message: '加载失败', type: 'error' });
            })
    });
}


/**
 * post上传文件方法,可以对应实现进度条
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} progress [进度条实现]
 * @param {Object} _timeout [超时时间]
 */
// param => {key: {val:val, reg: reg, title: title}}
export function postVideo(url, params,progress,_timeout) {
    let timeout = 10;
    if (_timeout !== undefined){
        timeout = _timeout
    }
    axios.defaults.timeout = 1000 * timeout;
    return new Promise((resolve, reject) => {
        let data = new FormData();
        let upload_url = "";//localStorage.getItem("uploader_api"); // 视频上传地址
        data.append('uploadfile',params.file)
        axios.post(upload_url+url, data,{
            headers:{
                "Content-Type":'multipart/form-data;charset-utf-8',
                "Name":params.name,
                "Token":localStorage.getItem("token"),
                "Uid":localStorage.getItem("uid"),
            },
            onUploadProgress:(event)=>{
                if (progress !== undefined && typeof progress === "function"){
                    progress(event)
                }
            },
        }).then(res => {
            resolve(res.data);
        })
            .catch(err => {
                reject(err.data)
                console.log(err);
                ElMessage({ message: '加载失败', type: 'error' });
            })
    });
}


/**
 * post方法，参数序列化
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
// param => [{key:key, val:val, reg: reg, title: title , num: num}]
export function qspost(url, params, ac) {
    axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    var param = {}
    let i = 0;
    if (params) {
        params.some(v => {
            if (v.reg) if (orbital(v) !== "OK") return i = 1;
            param[v.key] = v.val;
        });
    }
    param['token'] = localStorage.getItem("token")
    param['uid'] = localStorage.getItem("uid")
    param['ac'] = ac
    return new Promise((resolve, reject) => {
        if (i == 1) return [];
        axios.post(url, Qs.stringify(param))
            .then(res => {
                if (res.data.code == 40000) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("nickname");
                    localStorage.removeItem("current_router")
                    setTimeout(()=>{
                        location = '/#/login';
                    },200)
                    return
                }
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
                // Loading.service(true).close();
                ElMessage({ message: '加载失败', type: 'error' });
            })
    });
}


/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
                // Loading.service(true).close();
                ElMessage({ message: '加载失败', type: 'error' });
            })
    });
}


/**
 * delete
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function deletefn(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
                // Loading.service(true).close();
                ElMessage({ message: '加载失败', type: 'error' });
            })
    });
}
